import { Measure, Unit } from "convert-units";

export type CoverageUnits = CoverageMetricUnits | CoverageImperialUnits;
export type DensitySystems = "metric" | "imperial";

export type CoverageMetricUnits = "ha/h";
export type CoverageImperialUnits = "ac/h";

const metric: Record<CoverageMetricUnits, Unit> = {
  "ha/h": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1,
  },
};

const imperial: Record<CoverageImperialUnits, Unit> = {
  "ac/h": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1,
  },
};

const measure: Measure<DensitySystems, CoverageUnits> = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: 2.4712,
      },
    },
    imperial: {
      metric: {
        ratio: 1 / 2.4712,
      },
    },
  },
};

export default measure;
