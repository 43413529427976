import { Measure, Unit } from "convert-units";

export type DensityUnits = DensityMetricUnits | DensityImperialUnits;
export type DensitySystems = "metric" | "imperial";

export type DensityMetricUnits = "/m2" | "/ha" | "/km2";
export type DensityImperialUnits = "/in2" | "/ft2" | "/ac" | "/mi2";

const metric: Record<DensityMetricUnits, Unit> = {
  "/m2": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1,
  },
  "/ha": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1 / 10_000,
  },
  "/km2": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1 / 1_000_000,
  },
};

const imperial: Record<DensityImperialUnits, Unit> = {
  "/in2": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 144,
  },
  "/ft2": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1,
  },
  "/ac": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1 / 43_560,
  },
  "/mi2": {
    name: {
      singular: "",
      plural: "",
    },
    to_anchor: 1 / 27_878_400,
  },
};

const measure: Measure<DensitySystems, DensityUnits> = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: 1 / 10.7639,
      },
    },
    imperial: {
      metric: {
        ratio: 10.7639,
      },
    },
  },
};

export default measure;
